import Paragraph from 'components/Paragraph'

type PriceAndDiscountProps = {
    price: string
    discountPrice: string | null
    trialDays: number | null
    discountPct: number | null
    isDiscountAbs: number | null
    discountAbsDisplay: string | null
    currencySymbol: string | null
}

const PriceAndDiscount = (props: PriceAndDiscountProps) => {
    const {
        trialDays,
        discountPct,
        discountAbsDisplay,
        price,
        discountPrice,
        isDiscountAbs,
        currencySymbol = '$',
    } = props

    const isTrialAndPctDiscount = !!trialDays && !!discountPct
    const isTrialAndAbsDiscount = !!trialDays && !!isDiscountAbs && !!discountAbsDisplay
    const isNoTrialAndAbsOrAbsDiscount = !!discountPct || !!isDiscountAbs
    const isTrial = !!trialDays

    const priceToPayId = 'first-payment-amount'

    if (isTrial)
        return (
            <Paragraph color="primaryDark" mb="0!important" fontWeight="bold" fontSize="40px">
                <span data-cy={priceToPayId}>{currencySymbol}0</span> today
            </Paragraph>
        )

    if (isTrialAndPctDiscount)
        return (
            <Paragraph color="primaryDark" mb="0!important" fontWeight="bold" fontSize="40px">
                {trialDays} days free and {discountPct}% off
            </Paragraph>
        )

    if (isTrialAndAbsDiscount)
        return (
            <Paragraph color="primaryDark" mb="0!important" fontWeight="bold" fontSize="40px">
                {trialDays} days free and {discountAbsDisplay} off
            </Paragraph>
        )

    if (isNoTrialAndAbsOrAbsDiscount)
        return (
            <Paragraph mb="0!important">
                <Paragraph
                    as="span"
                    color="neutral.700"
                    fontSize="40px"
                    mr="1rem"
                    style={{ textDecoration: 'line-through' }}
                >
                    {price}
                </Paragraph>
                <Paragraph
                    as="span"
                    color="primaryDark"
                    fontWeight="bold"
                    fontSize="40px"
                    data-cy={priceToPayId}
                >
                    {discountPrice}
                </Paragraph>
            </Paragraph>
        )

    return (
        <Paragraph
            color="primaryDark"
            mb="0!important"
            fontWeight="bold"
            fontSize="40px"
            data-cy={priceToPayId}
        >
            {price}
        </Paragraph>
    )
}

export default PriceAndDiscount
